import { render, staticRenderFns } from "./careers-list-table.vue?vue&type=template&id=edd4354e"
import script from "./careers-list-table.vue?vue&type=script&lang=js"
export * from "./careers-list-table.vue?vue&type=script&lang=js"
import style0 from "./careers-list-table.vue?vue&type=style&index=0&id=edd4354e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QTable} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable})
