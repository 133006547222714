
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import {mapActions, mapGetters} from 'vuex'
export default {
  components: { Treeselect },
  data() {
    return {
      logo: require('../../../assets/logo.svg'),
      // logoDark: require("../../assets/images/logo/logo-dark.png"),
      // vacancies: [
      //   {
      //     title: 'Front End Engineer',
      //     team: 'Engineering',
      //     subTeam: 'Front End',
      //     location: 'Alexandria',
      //     type: 'Full Time',
      //   },
      //   {
      //     title: 'Software Engineer',
      //     team: 'Engineering',
      //     subTeam: 'Front End',
      //     location: 'Cairo',
      //     type: 'Full Time',
      //   },
      //   {
      //     title: 'Software Engineer',
      //     team: 'Engineering',
      //     subTeam: 'Back End',
      //     location: 'Cairo',
      //     type: 'Full Time',
      //   },
      //   {
      //     title: 'Software Engineer',
      //     team: 'Engineering',
      //     subTeam: 'Back End',
      //     location: 'Alexandria',
      //     type: 'Part Time',
      //   },
      //   {
      //     title: 'UI/UX Designer',
      //     team: 'Design',
      //     subTeam: 'Design',
      //     location: 'Alexandria',
      //     type: 'Internship',
      //   },
      // ],
      columns: [
        {
          value: 'title',
          text: 'Title',
          align: 'center',
        },

        {
          value: 'subTeam',
          text: 'Team',
          align: 'center',
        },
        {
          value: 'location',
          text: 'Location',
          align: 'center',
        },
        {
          value: 'type',
          text: 'Type',
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 0,
      },
      options: [
        {
          id: 'Engineering',
          label: 'Engineering',
          children: [
            {
              id: 'Front End',
              label: 'Front End',
            },
            {
              id: 'Back End',
              label: 'Back End',
            },
          ],
        },
        {
          id: 'Design',
          label: 'Design',
        },
      ],
      locations: [
        {
          id: 'Alexandria',
          label: 'Alexandria',
        },
        // {
        //   id: 'Cairo',
        //   label: 'Cairo',
        // },
      ],
      types: [
        {
          id: 'Full Time',
          label: 'Full Time',
        },
        {
          id: 'Part Time',
          label: 'Part Time',
        },
        {
          id: 'Internship',
          label: 'Internship',
        },
      ],
      search: '',
      teamFilter: null,
      typeFilter: null,
      locationFilter: null,
      items: ['Full Time', 'Part Time', 'Internship'],
    };
  },
  computed: {
    filteredVacancies() {
      const filteredTeams = this.filteredTeams();
      const filteredTypes = this.filteredTypes(
        filteredTeams,
      );
      const vacancies = this.filteredLocations(
        filteredTypes,
      );
      return vacancies;
    },
    ...mapGetters({
      vacancies:'careers/vacancies'
    })
  },
  async created(){
    await this.doFetchVacancies()
  },
  methods: {
    reset(){
      this.teamFilter = null
      this.typeFilter = null
      this.locationFilter = null
    },
    filteredTeams() {
      const parsedobj = JSON.parse(
        JSON.stringify(this.teamFilter),
      );
      if (parsedobj === null || parsedobj.length === 0) {
        return this.vacancies;
      }
      const vacancies = this.vacancies.filter((val) => {
        return this.teamFilter.some((f) => {
          return f === val.team || f === val.subTeam;
        });
      });
      return vacancies;
    },
    filteredTypes(data) {
      const parsedobj = JSON.parse(
        JSON.stringify(this.typeFilter),
      );
      if (parsedobj === null || parsedobj.length === 0) {
        return data;
      }
      const vacancies = data.filter((val) => {
        return this.typeFilter.some((f) => {
          return f === val.type;
        });
      });
      return vacancies;
    },
    filteredLocations(data) {
      const parsedobj = JSON.parse(
        JSON.stringify(this.locationFilter),
      );
      if (parsedobj === null || parsedobj.length === 0) {
        return data;
      }
      const vacancies = data.filter((val) => {
        return this.locationFilter.some((f) => {
          return f === val.location;
        });
      });
      return vacancies;
    },
    viewItem(item){
      console.log(item)
      this.$router.push(`/careers/${item.id}`)
    },
    ...mapActions({
      doFetchVacancies:'careers/doFetchVacancies'
    })
  },
};
