
// import HeaderOnePage from "../../layout/components/header.vue";
import Banner from '../../home/components/banner.vue';
import CareersListTable from '../../careers/components/careers-list-table.vue';
import Footer from '../../footer/components/footer';
import { mapGetters } from 'vuex';
export default {
  components: {
    // HeaderOnePage,
    Banner,
    Footer,
    CareersListTable,
  },
  data() {
    return {
      // logo: require("../../../assets/logo.svg"),
      // logoLight: require("../../../assets/logoLight.svg"),
      // logoDark: require("../../assets/images/logo/logo-dark.png"),
    };
  },
  computed: {
    ...mapGetters({
      loading: 'careers/loading',
    }),
  },
};
